<template>
  <div>
    <div class="row justify-content-center align-items-md-center">
      <div class="col-md-12">
        <div class="card full-height bg-ligth">
          <div class="card-header">
            <h3 class="mb-0">Pedidos de compra</h3>
          </div>
          <div class="card-body">
            <div class="row d-flex align-items-end">
              <div class="form-group col-md-3">
                <label for="selectFilterType">Tipo</label>
                <select id="selectFilterType" v-model="pesquisa.tipo" class="form-control form-control-sm">
                
                   <option value="nome_usuario">Usuario</option>
                  <option value="data_entrega">Data de entrega</option>
                  <option value="emissao">Data de emissão</option>
                  <option value="centro_custo">Centro de custo</option>
                </select>
              </div>

              <div class="form-group col-md-3">
                <label for="text">Pesquisa</label>
                <input
                    v-model="pesquisa.texto"
                    type="text"
                    class="form-control form-control-sm"
                    id="text"
                    placeholder
                />
              </div>
              <div class="form-group">
                <button class="btn btn-light btn-sm">
                  <i class="fa fa-search search-icon m-0 p-0 text-primary"></i>
                </button>
              </div>
            </div>

            <b-table
                sort-icon-left
                :fields="pedido_compra_fields"
                :items="lista_finan_pedido_compra"
                :per-page="perPage"
                :current-page="currentPage"
                id="pedidos_compra-table"
                class="table table-head-custom table-vertical-center table-head-bg table-borderless"
                show-empty
                empty-text="Nenhum registro encontrado!"
            >
              <template #head(acao)><span></span></template>

              <template #cell(valor_total)="{item}">
                {{ item.valor_total | formatMoney }}
              </template>

              <template #cell(emissao)="{item}">
                {{ item.emissao | formatDBDateToLocale }}
              </template>

              <template #cell(data_entrega)="{item}">
                {{ item.data_entrega | formatDBDateToLocale }}
              </template>

              <template #cell(status)="{item}">
                <span @click="showAprovacaoPendenciaModal(item)" v-if="item.status === 2"
                      class="btn label label-inline label-light-success"> Aprovado </span>
                <span @click="showAprovacaoPendenciaModal(item)" v-if="item.status !== 2"
                      class="btn label label-inline label-light-warning"> Pendente </span>
              </template>

              <template #cell(acao)="{item}">
                <div class="text-right w-100">
                  <b-button
                      :disabled="item.status === 0"
                      @click="anexarNotafiscalModal(item)"
                      class="btn btn-icon btn-light btn-sm mx-1"
                      v-b-tooltip.hover title="Anexar nota fiscal"
                  >
                    <i class="far fa-clipboard text-info"></i>
                  </b-button>
                  <b-button
                      @click="showAprovacaoPendenciaModal(item)"
                      class="btn btn-icon btn-light btn-sm mx-1"
                      v-b-tooltip.hover title="Aprovações pendente"
                  >
                    <i class="fa fa-user-clock text-warning"></i>
                  </b-button>

                  <b-button
                      @click="showItensPedidoModal(item)"
                      class="btn btn-icon btn-light btn-sm mx-1"
                      v-b-tooltip.hover title="Ver itens"
                  >
                    <i class="flaticon-eye text-success"></i>
                  </b-button>

                  <button
                      @click="atualizar(item)"
                      class="btn btn-icon btn-light btn-sm mx-1"
                      v-b-tooltip.hover title="Editar registro"
                  >
                    <i class="flaticon-edit-1 text-primary"></i>
                  </button>

                  <div class="d-inline-flex">
                    <span id="block-button-wrapper">
                      <button
                          disabled
                          @click="confirm(item)"
                          class="btn btn-icon btn-light btn-sm mx-1 disabled"
                          v-b-tooltip.hover title="Bloquear/desbloquear registro"
                      >
                        <i class="flaticon-lock text-danger"></i>
                      </button>
                      </span>
                    <b-tooltip target="block-button-wrapper">Aguardando aprovação</b-tooltip>
                  </div>

                </div>
              </template>
            </b-table>
          <b-pagination
              v-model="currentPage"
              :total-rows="lista_finan_pedido_compra.length"
              :per-page="perPage"
              aria-controls="pedidos_compra-table"
          >
          </b-pagination>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="items-pedido-compra" v-model="modal_itens.show" title="Itens do pedido" centered hide-footer>
      <b-overlay :show="false" rounded="sm">
        <b-table
            :fields="['id','produto', 'valor', 'quantidade']"
            :items="[]"
            id="lista-itens"
            class="table table-head-custom table-vertical-center table-head-bg table-borderless"
            show-empty
            empty-text="Nenhum registro encontrado!"
        >
        </b-table>
      </b-overlay>
    </b-modal>

    <b-modal ref="aprovacao-pendente" v-model="modal_pendencia.show" title="Aprovações pendentes" centered hide-footer>
      <b-overlay :show="modal_pendencia.loading" rounded="sm">
        <b-table
            :fields="['id','aprovador', 'status']"
            :items="lista_finan_aprovacao"
            id="lista-aprovacao"
            class="table table-head-custom table-vertical-center table-head-bg table-borderless"
            show-empty
            empty-text="Nenhum registro encontrado!"
        >
          <template #cell(status)="{item}">
            <span v-if="item.status === 1" class="label label-inline label-light-success"> Aprovado </span>
            <span v-if="item.status === 0" class="label label-inline label-light-warning"> Pendente </span>
          </template>
        </b-table>
      </b-overlay>
    </b-modal>

    <b-modal ref="anexar-nota-fiscal" v-model="modal_nota_fiscal.show" title="Anexar nota fiscal" centered hide-footer @hidden="hideModalNotafiscal">
      <b-overlay :show="modal_nota_fiscal.loading" rounded="sm">
        <div v-if="finan_nota_fiscal_entrada && finan_nota_fiscal_entrada.anexo">
          <b-button @click.prevent="openFile(finan_nota_fiscal_entrada.anexo)">
            <i class="far fa-clipboard"></i>
            {{ finan_nota_fiscal_entrada.anexo.arquivo_original }}
          </b-button>
        </div>

        <div v-else>
          <b-form>
            <label>Forma de Pagamento :*</label>
            <select class="form-control" v-model="modal_nota_fiscal.finan_forma_pagamento_id">
              <option
                  v-for="(lista, index) in lista_finan_modo_pagamentos"
                  :key="index"
                  :value="lista.id"
              >
                {{ lista.nome }}
              </option>
            </select>
          </b-form>

          <b-form class="mt-5">
            <label>Vencimento:*</label>
            <input
                required
                type="date"
                class="form-control"
                v-model="modal_nota_fiscal.vencimento"
            />
          </b-form>

          <b-form-group class="mt-5" label="Nota fiscal:" label-for="nota-fiscal">
            <b-form-file
                v-model="modal_nota_fiscal.anexo"
                id="nota-fiscal"
                placeholder="Nenhum arquivo selecionado"
                browse-text="Selecionar"
            ></b-form-file>
          </b-form-group>

          <b-form class="mt-5">
            <label>Descrição:*</label>
            <input
                required
                type="text"
                class="form-control"
            />
          </b-form>

          <b-button class="mt-10" @click.prevent="uploadNotaFiscal" variant="primary">Salvar</b-button>
        </div>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/store/breadcrumbs.module";
import moneyFormat from "@/core/mixins/moneyFormat";
import dateFormat from "@/core/mixins/dateFormat";
import JwtService, {getToken} from "@/core/services/jwt.service";
import {fireAlert} from "@/core/mixins/alertMessageMixin";
import {getFilialId} from "@/core/services/filial.service";

export default {
  mixins: [moneyFormat, dateFormat, fireAlert],
  data() {
    return {
        perPage: 10,
      currentPage: 1,
      pesquisa: {
        tipo: 'nome_sobrenome',
        texto: null
      },
      modal_pendencia: {
        pedido_compra_id: null,
        loading: false,
        show: false
      },
      modal_nota_fiscal: {
        anexo: null,
        vencimento: null,
        pedido_compra_id: null,
        finan_forma_pagamento_id: null,
        loading: false,
        show: false
      },
      modal_itens: {
        pedido_compra_id: null,
        loading: false,
        show: false
      },
      pedido_compra_fields: [
        {key: 'id', sortable: true},
        {key: 'nome_usuario', sortable: true},
      
        {key: 'valor_total', sortable: true},
        {key: 'emissao', sortable: true},
        {key: 'data_entrega', sortable: true},
        {key: 'status', sortable: true},
        'acao'
      ],
    
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: "Pedido Compra"}]);
  },
  computed: {
    lista_finan_modo_pagamentos() {
      return this.$store.state.financeiro.lista_finan_modo_pagamentos;
    },
    lista_finan_aprovacao() {
      return this.$store.state.finanOrcamentoAnual.lista_finan_aprovacao;
    },
    finan_nota_fiscal_entrada() {
      return this.$store.state.finanOrcamentoAnual.finan_nota_fiscal_entrada;
    },
    lista_finan_pedido_compra() {
      return this.$store.state.finanOrcamentoAnual.lista_finan_pedido_compra
          .filter(pedido => {
            return !this.pesquisa.texto || String(pedido[this.pesquisa.tipo]).toLowerCase().indexOf(this.pesquisa.texto.toLowerCase()) !== -1
          });
    },
    mensagem_alert() {
      return this.$store.state.configEmpresa.mensagem_alert;
    },
    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },
  },
  created() {
    this.listar_finan_pedido_compra();
  },
  methods: {
    openFile(anexo) {
      window.open(`${process.env.VUE_APP_API_URL}/anexo/open/${anexo.id}?filial_id=${getFilialId()}&token=${getToken()}`, 'blank')
    },
    async uploadNotaFiscal() {
      this.modal_nota_fiscal.loading = true

      let formData = new FormData
      formData.append('anexo', this.modal_nota_fiscal.anexo)
      formData.append('idPedido', this.modal_nota_fiscal.pedido_compra_id)
      formData.append('vencimento', this.modal_nota_fiscal.vencimento)
      formData.append('finan_forma_pagamento_id', this.modal_nota_fiscal.finan_forma_pagamento_id)

      await this.$store.dispatch('gerar_finan_nota_fiscal_entrada', formData)

      if (this.mensagem_alert.tipo === 'success') {
        this.hideModalNotafiscal()
        setTimeout(() => this.listar_finan_pedido_compra(), 2000)
      }

      this.fireAlert(this.mensagem_alert)
      this.modal_nota_fiscal.loading = false
    },
    hideModalNotafiscal() {
      this.$store.dispatch('clean_nota_fiscal_entrada')
      this.modal_nota_fiscal.show = false
      this.modal_nota_fiscal.anexo = null
      this.modal_nota_fiscal.pedido_compra_id = null
    },
    async anexarNotafiscalModal(item) {
      this.modal_nota_fiscal.pedido_compra_id = item.id
      this.modal_nota_fiscal.finan_forma_pagamento_id = item.finan_forma_pagamento_id
      this.modal_nota_fiscal.loading = true
      this.modal_nota_fiscal.show = true
      if (item.nota_fiscal && item.nota_fiscal.id) {
        await this.$store.dispatch('show_finan_nota_fiscal_entrada', item.nota_fiscal.id)
      }
      this.modal_nota_fiscal.loading = false
    },
    async showAprovacaoPendenciaModal(item) {
      this.modal_pendencia.loading = true
      this.modal_pendencia.pedido_compra_id = item.id
      this.modal_pendencia.show = true
      await this.$store.dispatch('listar_finan_aprovacao', {
        params: {pedido_compra_id: item.id}
      })
      this.modal_pendencia.loading = false
    },
    showItensPedidoModal(value) {
      // this.modal_itens.loading = true
      // this.modal_itens.pedido_compra_id = item.id
      // this.modal_itens.show = true
      // // fetch some data
      // this.modal_itens.loading = false
       
            //  this.$store.dispatch("atualizar", value);
      this.$router.push({name: "finanContasPagar", query: {pedido_id: value.id}});
    },
    async listar_finan_pedido_compra() {
      this.$store.dispatch("MudarPreloader", true);

      const {usuario_id} = this.$route.params
      if (!usuario_id) {
        this.fireAlert({title: 'Nenhum Usuario foi selecionado!', tipo: 'error', routeName: 'gerenFuncionarios', timer: 5000})
        this.$store.dispatch("MudarPreloader", false);
        return
      }
      await this.$store.dispatch("listar_finan_modo_pagamento");
      await this.$store.dispatch("listar_finan_pedido_compra_usuario",usuario_id).finally(() => {
        this.$store.dispatch("MudarPreloader", false);
        this.$store.dispatch("atualizar", "");
      });
    },
    atualizar(value) {
      this.$router.push({name: "createPedidoCompra"});
      this.$store.dispatch("atualizar", value);
    },
    async confirm(value) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação bloqueara esse Pedido Compra no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            this.bloquear(value);
          }
        },
      });
    },
    async bloquear(value) {
      await this.$store.dispatch("bloquear_finan_pedido_compra", value);
      this.alertMessage(this.mensagem_alert.tipo, this.mensagem_alert.message);
    },

    alertMessage(tipo, mensagem) {
      this.$fire({
        title: "Aviso",
        text: mensagem,
        type: tipo,
        timer: 3000,
      }).then((r) => {
        console.log(r.value);
      });
    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>